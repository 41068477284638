import { extend } from 'flarum/extend';
import app from 'flarum/app';
import LogInButtons from 'flarum/components/LogInButtons';
import LogInButton from 'flarum/components/LogInButton';

$(document).ready(function(){
  $('body').append('<div class="logoutIframe"><iframe id="logoutIframe" src="/auth/garena/logout" style="display:none"></iframe></div>');
});

app.initializers.add('flarum-auth-garena', () => {
  extend(LogInButtons.prototype, 'items', function (items) {
    items.add('garena', m(
      LogInButton,
      {
        className: 'Button LogInButton--garena',
        icon: 'fa-garena',
        path: '/auth/garena?platform=1' },
      app.translator.trans('flarum-auth-garena.forum.log_in.with_garena_button')
    ));
    items.add('garenafb', m(
      LogInButton,
      {
        className: 'Button LogInButton--facebook',
        icon: 'fab fa-facebook',
        path: '/auth/garena?platform=3' },
      app.translator.trans('flarum-auth-garena.forum.log_in.with_garena_fb_button')
    ));
    $(".Form--centered").remove();
    $(".LogInModal-forgotPassword").remove();
  });
});
